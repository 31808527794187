(function (w) {
    w.optimizelyUtil = {
        _buildObj: function (attr) {
            if (!attr) return;

            let items = attr.split(',');
            let result = {};
            for (var i = 0; i < items.length; i++) {
                let item = items[i];
                let parts = item.split(':');
                result[parts[0]] = parts[1];
            }

            return result;
        },
        _experiments: [],
        _scanAndFire: function (selector) {
            if (!w.optimizelyClient) {
                console.error("Not initialized. Please call init method first");
                return;
            }

            if (!selector) {
                selector = '[data-opm-trackon]';
            }

            var elms = document.querySelectorAll(selector);
            if (!elms) {
                console.warn(`No item found with ${selector}`);
                return;
            }

            const me = this;
            for (let i = 0; i < elms.length; i++) {
                const elm = elms[i];

                const eventType = elm.getAttribute('data-opm-trackon');
                if (!eventType) continue;
                const eventName = elm.getAttribute('data-opm-event') || eventType;
                const eventData = me._buildObj(elm.getAttribute('data-opm-data'));

                elm.addEventListener(eventType, ((name, data) =>
                    me.track(name, data)).bind(null, eventName, eventData));
            }
        },

        init: function () {
            if (!w.optimizelyData || !w.optimizelyData.expData) {
                console.error("Optimizely data not defined. So no instance created");
                return;
            }

            optimizelySdk.setLogLevel(w.optimizelyData.logLevel || "error");
            w.optimizelyClient = optimizelySdk.createInstance({
                datafile: w.optimizelyData.expData
            });
            document.dispatchEvent(new CustomEvent("optimizely-init"));
        },

        // not safe when multiple requests at same time
        activate: function (exp) {
            if (!w.optimizelyClient) {
                console.error("Not initialized. Please call init method first");
                return;
            }

            for (var i = 0; i < this._experiments.length; i++) {
                if (this._experiments[i].name == exp) {
                    return this._experiments[i].variation;
                }
            }

            let result = w.optimizelyClient.activate(exp, w.optimizelyData.userId, w.optimizelyData.attributes);

            this._experiments.push({ name: exp, variation: result });

            return result;
        },
        getVariation: function (exp) {
            return w.optimizelyClient.getVariation(exp, w.optimizelyData.userId, w.optimizelyData.attributes);
        },
        isFeatureEnabled: function(exp) {
            return w.optimizelyClient.isFeatureEnabled(exp, w.optimizelyData.userId, w.optimizelyData.attributes);
        },
        track: function (event, data) {
            if (!w.optimizelyClient) {
                console.error("Not initialized. Please call init method first");
                return;
            }

            w.optimizelyClient.track(event, w.optimizelyData.userId, w.optimizelyData.attributes, data);
        },
        scanAndFire: function (selector) {
            this._scanAndFire(selector);
        }
    };

    w.document.addEventListener("DOMContentLoaded", function () {
        w.optimizelyUtil.init();
        w.optimizelyUtil.scanAndFire();
    }, { capture: true });

})(window);